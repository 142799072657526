<template>
  <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start mt-14 z-[25]">
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition v-for="(notification, index) in notificationsList" :key="index" enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div class="p-4">
            <div class="flex items-start">
              <div v-if="notification.notificationType === 'success'" class="flex-shrink-0 text-green-500">
                <CheckCircleIcon class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div v-if="notification.notificationType === 'error'" class="flex-shrink-0 text-red-500">
                <ExclamationCircleIcon class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div v-if="notification.notificationType === 'warning'" class="flex-shrink-0 text-yellow-500">
                <ExclamationIcon class="h-6 w-6 " aria-hidden="true" />
              </div>
              <div v-if="notification.notificationType === 'info'" class="flex-shrink-0 text-blue-500">
                <InformationCircleIcon class="h-6 w-6 " aria-hidden="true" />
              </div>

              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium" v-html="notification.content" />
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button type="button" @click="closeNotification(notification.id)" class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
  
<script setup>
import { CheckCircleIcon, XIcon, ExclamationIcon, InformationCircleIcon, ExclamationCircleIcon } from '@heroicons/vue/solid'
import { computed, onMounted } from "vue";
import eventBus from "@/plugins/eventBus"
import { useNotificationStore } from "@/stores/notification.store";
import { storeToRefs } from "pinia";

const notificationStore = useNotificationStore()
const { notifications } = storeToRefs(notificationStore)

const closeNotification = (id) => notificationStore.closeNotification(id)
const notificationsList = computed(() => notifications.value.filter((notification) => notification))

onMounted(() => {
  eventBus.$on('CREATE_NOTIFICATION', (message, notificationType = null, timeout = null) => notificationStore.addNotification(message, notificationType, timeout))
})
</script>

