<template>
  <div class="mt-8 mb-2">
    <div class="flex flex-col items-center">
      <div
        class="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600"
      >
        <img v-if="user?.profile_picture" :src="user.profile_picture" class="h-full w-full"/>
        <span v-else class="font-medium text-gray-600 dark:text-gray-300">{{
          initials()
        }}</span>
      </div>
      <h5 class="mb-2 text-gray-800" v-if="user">{{ user.name }}</h5>

      <p class="text-gray-600 text-xs text-center" v-if="roles?.length > 0">
        <span class="font-bold"> {{ position?.friendly_name }} </span>
        <br />
        <span v-for="role in roles" :key="role.id" class="text-orange">
          ({{ role.friendly_name }})
        </span>
      </p>
    </div>

    <div
      v-if="
        loadingSummaryReport ||
        loadingAssignedContentInProgressCount ||
        loadingAssignedContentCompletedCount ||
        loadingAssignedContentOverdueCount
      "
    >
      <SkeletonMyProgress
        :is-loading="
          loadingSummaryReport ||
          loadingAssignedContentInProgressCount ||
          loadingAssignedContentCompletedCount ||
          loadingAssignedContentOverdueCount
        "
        :hasViewTeamProgressPermission="hasViewTeamProgressPermission"
      />
    </div>
    <template v-else>
      <div class="mt-8 flex items-stretch">
        <div
          class="w-full md:w-1/2 bg-gray-100 py-8 px-4 mr-2 cursor-pointer"
          @click="viewDetails('completed')"
        >
          <h3 class="mb-1 text-gray-800 text-center text-3xl font-medium">
            {{ completedCourseCount }}
          </h3>
          <p class="text-sm text-center text-gray-600">Courses Completed</p>
        </div>
        <div
          class="w-full md:w-1/2 bg-gray-100 py-8 px-4 ml-2 cursor-pointer"
          @click="viewDetails('certificates')"
        >
          <h3 class="mb-1 text-gray-800 text-center text-3xl font-medium">
            {{ certificatesCount }}
          </h3>
          <p class="text-sm text-center text-gray-600">Certificates Earned</p>
        </div>
      </div>
      <div class="mt-4 flex items-stretch">
        <div
          class="w-full md:w-1/2 bg-gray-100 py-8 px-4 mr-2 cursor-pointer"
          @click="viewDetails('in-progress')"
        >
          <h3 class="mb-1 text-gray-800 text-center text-3xl font-medium">
            {{ inProgressCount }}
          </h3>
          <p class="text-sm text-center text-gray-600">Courses in Progress</p>
        </div>
        <div
          class="w-full md:w-1/2 bg-gray-100 py-8 px-4 ml-2 cursor-pointer"
          @click="viewDetails('assigned')"
        >
          <h3 class="mb-1 text-gray-800 text-center text-3xl font-medium">
            {{ assignedCount }}
          </h3>
          <p class="text-sm text-center text-gray-600">Assigned to You</p>
        </div>
      </div>
      <div v-if="hasViewTeamProgressPermission">
        <div class="flex flex-col items-center">
          <h5 class="mt-8 mb-2 text-gray-800">Your Team’s Progress</h5>
        </div>
        <hr class="mb-6 border-t border-untitled-gray-300" />
        <div class="mt-4 flex flex-col md:flex-row gap-2 items-center">
          <div
            class="w-full md:w-1/3 bg-gray-100 py-8 cursor-pointer has-tooltip"
            @click="goToAssignmentsCompleted()"
          >
            <span
              class="tooltip shadow-lg text-center bg-untitled-gray-200 text-untitled-gray-500 rounded py-2 mt-24 px-2 mr-4 w-[12rem]"
              >This number represents the total number of completed assignments
              for your team.</span
            >
            <h3 class="mb-1 text-gray-800 text-center text-3xl font-medium">
              {{ completedAssignedContentsCount }}
            </h3>
            <p class="text-sm text-center text-gray-600">
              Completed <br />  Assignments
            </p>
          </div>
          <div
            class="w-full md:w-1/3 bg-gray-100 py-8 cursor-pointer has-tooltip"
            @click="goToAssignmentsInProgress()"
          >
            <span
              class="tooltip shadow-lg text-center bg-untitled-gray-200 text-untitled-gray-500 rounded py-2 mt-24 px-2 ml-4 w-[12rem"
              >This number represents the total number of in-progress
              assignments for your team.</span
            >
            <h3 class="mb-1 text-gray-800 text-center text-3xl font-medium">
              {{ inProgressAssignedContentsCount }}
            </h3>
            <p class="text-sm text-center text-gray-600">
              In Progress <br />  Assignments
            </p>
          </div>
          <div
            class="w-full md:w-1/3 bg-gray-100 py-8 cursor-pointer has-tooltip"
            @click="goToAssignmentsPastOverdue()"
          >
            <span
              class="tooltip shadow-lg text-center bg-untitled-gray-200 text-untitled-gray-500 rounded py-2 mt-24 px-2 ml-4 w-[12rem"
              >This number represents the total number of past due
              assignments for your team.</span
            >
            <h3 class="mb-1 text-gray-800 text-center text-3xl font-medium">
              {{ pastDueAssignedContentsCount }}
            </h3>
            <p class="text-sm text-center text-gray-600">
              Past Due <br /> Assignments
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
  <CompletedCoursesModal
    v-if="show_completed_courses_modal"
    :courses="courses"
    @close="closeDialog"
  />
  <CourseInProgressModal
    v-if="show_in_progress_courses_modal"
    :courses="courses"
    @close="closeDialog"
  />
  <AssignedCourseModal
    v-if="show_assigned_courses_modal"
    :courses="courses"
    @close="closeDialog"
  />
  <CertificatesEarnedModal
    v-if="show_certificates_earned_modal"
    :courses="courses"
    @close="closeDialog"
  />
</template>
<script>
import { ref, inject, defineComponent } from "vue";
import CompletedCoursesModal from "../my-progress/CompletedCoursesModal.vue";
import CourseInProgressModal from "../my-progress/CourseInProgressModal.vue";
import AssignedCourseModal from "../my-progress/AssignedCourseModal.vue";
import CertificatesEarnedModal from "../my-progress/CertificatesEarnedModal.vue";
import { useAuthStore } from "@/stores";
import { computed } from "@vue/reactivity";
import SkeletonMyProgress from "@/components/loaders/SkeletonMyProgress";
import Bugsnag from "@bugsnag/js";
import { router } from "@/router";

export default defineComponent({
  components: {
    CompletedCoursesModal,
    CourseInProgressModal,
    AssignedCourseModal,
    CertificatesEarnedModal,
    SkeletonMyProgress,
  },
  setup() {
    const user = ref({
      name: "Webinarinc User",
      display_picture:
        "https://webinarinc-v2-development.s3-us-west-2.amazonaws.com/default/default_profile_picture.jpg",
    });

    const roles = ref([]);
    const position = ref(null);
    const axios = inject("axios");

    const progress_type = ref(null);
    const courses = ref([]);
    const certificates = ref([]);
    const show_completed_courses_modal = ref(false);
    const show_in_progress_courses_modal = ref(false);
    const show_assigned_courses_modal = ref(false);
    const show_certificates_earned_modal = ref(false);
    const loadingSummaryReport = ref(false);
    const loadingAssignedContentCompletedCount = ref(false);
    const loadingAssignedContentInProgressCount = ref(false);
    const loadingAssignedContentOverdueCount = ref(false);

    const summary = ref({
      courses_completed_count: 0,
      courses_in_progress_count: 0,
      total_assigned_count: 0,
      total_certificates_count: 0,
    });
    const assignedContentsSummary = ref({
      completed_count: 0,
      in_progress_count: 0,
      pat_due_count: 0
    });

    const auth = useAuthStore();

    const goToAssignmentsCompleted = () => {
      router.push({
        name: "reports",
        query: {
          tab: "assignment-history",
          statuses: ["Completed"],
        },
      });
    };

    const goToAssignmentsInProgress = () => {
      router.push({
        name: "reports",
        query: { tab: "assignment-history", statuses: ["In Progress"] },
      });
    };

    const goToAssignmentsPastOverdue = () => {
      router.push({
        name: "reports",
        query: { tab: "assignment-history", statuses: ["Overdue"] },
      });
    };


    const loadProfile = async () => {
      await axios
        .get("api/my-profile", {
          params: {
            user_id: auth.user?.id,
          },
        })
        .then((response) => {
          user.value = response.data;
          roles.value = user.value.roles;
          position.value = user.value.position;
        })
        .catch((error) => {
          Bugsnag.notify(error);
        });
    };

    const loadAssignedContentInProgressCount = () => {
      loadingAssignedContentInProgressCount.value = true;
      axios
        .get("/api/v3/reporting/assigned-histories", {
          params: {
            statuses: ["in_progress"],
          },
        })
        .then(({ data }) => {
          assignedContentsSummary.value.in_progress_count = data.meta.total;
        })
        .catch((error) => {
          Bugsnag.notify(error);
        })
        .finally(() => {
          loadingAssignedContentInProgressCount.value = false;
        });
    };
    const loadAssignedContentCompletedCount = () => {
      loadingAssignedContentCompletedCount.value = true;
      axios
        .get("/api/v3/reporting/assigned-histories", {
          params: {
            statuses: ["completed"],
          },
        })
        .then(({ data }) => {
          assignedContentsSummary.value.completed_count = data.meta.total;
        })
        .catch((error) => {
          Bugsnag.notify(error);
        })
        .finally(() => {
          loadingAssignedContentCompletedCount.value = false;
        });
    };
    const loadAssignedContentPastDueCount = () => {
      loadingAssignedContentOverdueCount.value = true;
      axios
        .get("/api/v3/reporting/assigned-histories", {
          params: {
            statuses: ["overdue"],
          },
        })
        .then(({ data }) => {
          assignedContentsSummary.value.pat_due_count = data.meta.total;
        })
        .catch((error) => {
          Bugsnag.notify(error);
        })
        .finally(() => {
          loadingAssignedContentOverdueCount.value = false;
        });
    };

    function viewDetails(type) {
      progress_type.value = type;

      showModalsChecker();
    }

    const showModalsChecker = () => {
      switch (progress_type.value) {
        case "completed":
          show_completed_courses_modal.value = true;
          break;
        case "in-progress":
          show_in_progress_courses_modal.value = true;
          break;
        case "assigned":
          show_assigned_courses_modal.value = true;
          break;
        case "certificates":
          show_certificates_earned_modal.value = true;
          break;
      }
    };

    function closeDialog() {
      show_completed_courses_modal.value = false;
      show_in_progress_courses_modal.value = false;
      show_assigned_courses_modal.value = false;
      show_certificates_earned_modal.value = false;
    }

    function initials() {
      let initials = user.value.name.split(" ");

      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = user.value.name.substring(0, 2);
      }

      return initials.toUpperCase();
    }

    const loadSummaryReportCount = async () => {
      loadingSummaryReport.value = true;
      await axios
        .get("api/v3/user/my-progress")
        .then((response) => {
          summary.value = response.data;
        })
        .catch((error) => {
          Bugsnag.notify(error);
        })
        .finally(() => {
          loadingSummaryReport.value = false;
        });
    };

    const certificatesCount = computed(() => {
      return parseInt(summary.value?.total_certificates_count);
    });

    const assignedCount = computed(() => {
      return parseInt(summary.value?.total_assigned_count);
    });

    const inProgressCount = computed(() => {
      return parseInt(summary.value?.courses_in_progress_count);
    });

    const completedCourseCount = computed(() => {
      return parseInt(summary.value?.courses_completed_count);
    });

    const completedAssignedContentsCount = computed(() => {
      return parseInt(assignedContentsSummary.value?.completed_count);
    });

    const inProgressAssignedContentsCount = computed(() => {
      return parseInt(assignedContentsSummary.value?.in_progress_count);
    });

    const pastDueAssignedContentsCount = computed(() => {
      return parseInt(assignedContentsSummary.value?.pat_due_count);
    });

    const authUser = useAuthStore();

    const hasViewTeamProgressPermission = computed(() => {
      const user = authUser.$state.user;

      if (!user) {
        return false;
      }

      return user.roles.some(
        (role) =>
          role.name === "super-administrator" ||
          role.name === "account-manager" ||
          role.name === "specific-dealer-manager"
      );
    });

    loadProfile();
    loadSummaryReportCount();
    if (hasViewTeamProgressPermission.value) {
      loadAssignedContentInProgressCount();
      loadAssignedContentCompletedCount();
      loadAssignedContentPastDueCount();
    }

    return {
      user,
      roles,
      position,
      loadProfile,
      viewDetails,
      show_completed_courses_modal,
      show_in_progress_courses_modal,
      show_assigned_courses_modal,
      show_certificates_earned_modal,
      progress_type,
      courses,
      certificates,
      closeDialog,
      summary,
      initials,
      loadingSummaryReport,
      loadingAssignedContentInProgressCount,
      loadingAssignedContentCompletedCount,
      loadingAssignedContentOverdueCount,
      loadSummaryReportCount,
      certificatesCount,
      assignedCount,
      inProgressCount,
      completedCourseCount,
      showModalsChecker,
      hasViewTeamProgressPermission,
      completedAssignedContentsCount,
      inProgressAssignedContentsCount,
      pastDueAssignedContentsCount,
      goToAssignmentsCompleted,
      goToAssignmentsInProgress,
      goToAssignmentsPastOverdue
    };
  },
});
</script>
<style scoped>
.tooltip {
  visibility: hidden;
  position: absolute;
}
.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}
</style>
