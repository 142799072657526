<template>
  <div class="w-full">
    <div class="py-10 w-full px-6">
      <top-section :loading="isLoading"></top-section>
      <div
        class="mx-auto mt-6 md:h-[800px] lg:h-[2000px] overflow-y-scroll"
      >
        <div v-if="learningPaths.length > 0" role="list" class="flex flex-col">
          <div v-for="(learningPath, index) in learningPaths" :key="index">
            <playlist-card
              :reload="getLearningPaths"
              :pathId="learningPath.learning_path_id"
              :title="learningPath.title"
              :thumbnail="learningPath.thumbnail"
              :totalVideosCompleted="learningPath.total_completed_videos_count"
              :totalVideoDuration="learningPath.total_learning_path_duration"
              :totalVideos="learningPath.total_videos_count"
              :dueDate="learningPath.due_date"
              :contents="learningPath.contents"
              :isOwner="learningPath.is_owner"
              :hasPaidContents="learningPath.has_paid_contents"
            ></playlist-card>
          </div>
        </div>
        <div class="text-center mt-5">
          <button v-if="(!isLoading && !isLastPage && learningPaths.length > 0)" type="button" @click="viewMore()">View More Playlist</button>
        </div>
        <template v-if="isInit">

          <ul role="list" class="flex flex-col">
            <li v-for="i in 6" :key="i" class="flex flex-col rounded-lg bg-white">
              <SkeletonPlaylist />
            </li>
          </ul>
        </template>
        <div v-if="learningPaths.length <= 0 && !isLoading" class="text-center mt-5">
          <p class="text-gray-700">No Playlist Available.</p>
        </div>
      </div>
      <div class="text-center" v-if="isLoading && !isInit">
        <loading-state />
      </div>
    </div>
  </div>
</template>
<script>
import TopSection from "./TopSection.vue";
import { ref, inject, nextTick, computed, onMounted } from "vue";
import { forEach as _forEach } from "lodash-es";
import EventBus from "@/plugins/eventBus";
import LoadingState from '@/components/partials/LoadingState.vue';
import SkeletonPlaylist from "@/components/loaders/SkeletonPlaylist";
import PlaylistCard from "@/components/home/learningpath/PlaylistCard.vue";
import { useLearningPathStore } from '@/stores/learningPath';
import Bugsnag from "@bugsnag/browser";

export default {
  components: {
    PlaylistCard,
    TopSection,
    LoadingState,
    SkeletonPlaylist,
  },
  setup() {
    const axios = inject("axios");
    const filterBy = ref(null);
    const sortBy = ref("");
    const privacyBy = ref(null)
    const isLastPage = ref(false);
    const totalItems = ref(9);
    const isLoading = ref(false);
    const isInit = ref(true);
    const learningPathStore = useLearningPathStore();

    const learningPaths = computed(() => learningPathStore.learningPaths)

    const toggleLoading = () => {
      isLoading.value = !isLoading.value;
    }

    const applyFilterAndSort = () => {
      learningPathStore.$patch({ learningPaths: [] })
      isInit.value = true;
      getLearningPaths();
    }

    const getLearningPaths = async () => {
      toggleLoading()
      await axios
        .get("/api/v3/dashboard/learning-paths", {
          params: {
            total_items: totalItems.value,
            sort_by: sortBy.value,
            filter_by: filterBy.value,
            privacy_by: privacyBy.value
          },
        })
        .then(({ data }) => {
          if (totalItems.value !== 9) {
            learningPathStore.$patch({ learningPaths: [] });
          }

          _forEach(data.items, (learningPath) => {
            learningPathStore.addLearningPath(learningPath);
          });

          if(data.total_count_of_items <= totalItems.value) {
            isLastPage.value = true;
          }

          nextTick(() => {
            toggleLoading();
            isInit.value = false;
          });
        })
        .catch((error) => {
          nextTick(() => {
            toggleLoading();
          });
          Bugsnag.notify(error)
      });
    }

    const viewMore = () => {
      totalItems.value += 9;
      getLearningPaths();
    }

    // component mounted
    onMounted(() => {
      applyFilterAndSort();

      if(learningPaths.value.length) {
        isInit.value = false
      }

      EventBus.$on("REFRESH_LEARNING_PATH", () => {
        isInit.value = true;
        learningPathStore.$patch({ learningPaths: [] });
        getLearningPaths();
      });

      EventBus.$on("OPEN_ASK_USER_TO_ASSIGN_LEARNING_PATH_DIALOG", () => {
        getLearningPaths();
      });

      EventBus.$on("UPDATE_LEARNING_PATH_DASHBOARD_SORT_BY", (sortValue) => {
        sortBy.value = sortValue;
        applyFilterAndSort();
      });

      EventBus.$on("UPDATE_LEARNING_PATH_DASHBOARD_FILTER_BY", (filterValue) => {
        filterBy.value = filterValue;
        applyFilterAndSort();
      });

      EventBus.$on("UPDATE_LEARNING_PATH_DASHBOARD_PRIVACY_BY", (privacyByValue) => {
        privacyBy.value = privacyByValue;
        applyFilterAndSort();
      })
    })

    return {
      isLastPage,
      isLoading,
      isInit,
      learningPaths,
      viewMore,
      getLearningPaths
    };
  }
};
</script>
