<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-[85] w-full" @click="close">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-untitled-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-[85] overflow-y-auto">
                <div class="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all lg:my-8 lg:max-w-2xl md:my-8 md:max-w-md sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                            <div class="absolute top-0 right-0 hidden pt-4 pr-6 sm:block">
                                <button type="button" class="text-untitled-gray-400" @click="close">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                            <div class="flex mt-3 flex-col md:flex-row  lg:flex-row justify-between">
                                <DialogTitle as="h3" class="text-lg font-medium leading-6 text-untitled-gray-900">
                                    Assigned to You
                                </DialogTitle>

                                <div class="text-base w-auto lg:w-[200px] md:w-[190px]" >
                                    <ECombobox
                                    v-model="sortBy" 
                                    name="sorts" 
                                    :options="sorts" 
                                    option-name="name" 
                                    value-key="value" 
                                    placeholder="Sort By" 
                                    :multiple="false" 
                                    :filterable="false" 
                                    :clearable="false"/>
                                </div>
                            </div>
                            <div class="">

                                <div class="w-full">
                                    <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
                                        <li v-for="toggle in toggles" :key="toggle.id"
                                            class="-mb-px mr-2 last:mr-0 flex-auto text-center">
                                            <a class="text-xs font-normal uppercase px-5 py-3 shadow-lg rounded block leading-normal cursor-pointer hover:bg-untitled-gray-200 hover:text-untitled-gray-800"
                                                v-on:click="toggleTabs(toggle.id)" :class="{
                                                    'text-untitled-gray-600 bg-white': openTab !== toggle.id,
                                                    'text-untitled-gray-800 bg-untitled-gray-200': openTab === toggle.id,
                                                }">
                                                {{ toggle.name }} <span v-if="toggle.count >= 0"
                                                    class="text-untitled-gray-500">({{ toggle.count }})</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6  z-0">
                                        <div class="flex-auto">
                                            <div class="tab-content tab-space">

                                                <div :class="{ hidden: openTab !== 1, block: openTab === 1 }">
                                                    <LoadingState v-if="loadingUnits" class="mx-auto text-center" />
                                                    <div v-else>
                                                        <Suspense>
                                                            <ul v-if="filteredUnitsList.length > 0" role="list"
                                                                class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto"
                                                                :style="filteredUnitsList.length < 3 ? 'height:auto' : 'height: 600px;'">
                                                                <li class="grid py-6 px-4  shadow-md m-2"
                                                                    v-for="data in filteredUnitsList" :key="data.unit?.id">
                                                                    <div class="flex-shrink-0 aspect-auto">

                                                                        <img v-if="data.thumbnail?.includes('google')"
                                                                            src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                                                                            class="w-full h-40" />
                                                                        <img v-else-if="data?.thumbnail"
                                                                            :src="data?.thumbnail" class="w-full h-40"
                                                                            :on-error="'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg'" />

                                                                    </div>

                                                                    <div class="mt-2 flex flex-1 flex-col ">
                                                                        <div>
                                                                            <div class="flex justify-between">
                                                                                <h4 class="text-sm">
                                                                                    <a href="#"
                                                                                        class="font-medium text-gray-700 hover:text-gray-800">{{
                                                                                            data.unit?.name
                                                                                        }}</a>
                                                                                    <p class="capitalize">
                                                                                        <span
                                                                                            :class="getStatusColor(data.status)">
                                                                                            {{ data.status }}
                                                                                        </span>
                                                                                    </p>
                                                                                </h4>

                                                                            </div>
                                                                        </div>

                                                                        <div class="grid">
                                                                            <div class="w-full mx-auto ">
                                                                                <div class="flex justify-between pt-5">
                                                                                    <p 
                                                                                    class="text-xs text-left">
                                                                                    Assignor: {{ data.assigned_by }}
                                                                                    <br />
                                                                                    <span v-if="data.assigned_at"> 
                                                                                        Assigned Date:  {{ data.assigned_at }}
                                                                                    </span> 
                                                                                    <br />
                                                                                    <span v-if="data.due_date"> 
                                                                                        Due Date:  {{ data.due_date }}
                                                                                    </span>
                                                                                     </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <br />
                                                                        <p
                                                                            class="text-xs text-center text-untitled-gray-500 font-bold">
                                                                            <v-button
                                                                                @click="gotoUnit(data.unit_id)"
                                                                                type="button"
                                                                                class="font-medium text-xs">
                                                                                <svg v-if="data.is_stream"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke-width="1.5"
                                                                                    stroke="currentColor"
                                                                                    class="w-3 h-3 mr-1">
                                                                                    <path stroke-linecap="round"
                                                                                        stroke-linejoin="round"
                                                                                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                                                </svg>

                                                                                <svg v-else
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    fill="none"
                                                                                    viewBox="0 0 24 24"
                                                                                    stroke-width="1.5"
                                                                                    stroke="currentColor"
                                                                                    class="w-3 h-3 mr-1">
                                                                                    <path stroke-linecap="round"
                                                                                        d="M15.75 10.5l4.72-4.72a.75.75 0 011.28.53v11.38a.75.75 0 01-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25h-9A2.25 2.25 0 002.25 7.5v9a2.25 2.25 0 002.25 2.25z" />
                                                                                </svg>
                                                                                Open Unit
                                                                            </v-button>
                                                                        </p>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                            <p v-else class="text-center text-untitled-gray-500">No Units
                                                                Found</p>
                                                        </Suspense>
                                                    </div>

                                                </div>
                                                <div :class="{ hidden: openTab !== 2, block: openTab === 2 }">
                                                    <LoadingState v-if="loadingModules" class="mx-auto text-center" />
                                                    <div v-else>

                                                        <ul v-if="filteredModuleList.length > 0" role="list"
                                                            class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto"
                                                            :style="filteredModuleList.length < 3 ? 'height:auto' : 'height: 600px;'">
                                                            <li class="grid py-6 px-4  shadow-md m-2"
                                                                v-for="data in filteredModuleList" :key="data.id">
                                                                <div class="flex-shrink-0 aspect-auto">

                                                                    <img v-if="data.availableThumbnail && !data.thumbnail?.includes('vimeo')"
                                                                        :src="data.thumbnail" class="w-full h-[150px]" />
                                                                    <iframe v-else-if="data.thumbnail?.includes('vimeo')"
                                                                        title="" id="votd-vimeo" class="w-full h-[150px]"
                                                                        webkitallowfullscreen mozallowfullscreen
                                                                        allowfullscreen :src="data.thumbnail"></iframe>
                                                                    <img v-else
                                                                        src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                                                                        class="w-full h-40" />
                                                                </div>

                                                                <div class="mt-2 flex flex-1 flex-col ">
                                                                    <div>
                                                                        <div class="flex justify-between">
                                                                            <h4 class="text-sm">
                                                                                <a href="#"
                                                                                    class="font-medium text-gray-700 hover:text-gray-800">
                                                                                    {{ data.name }}</a>
                                                                                <p class="capitalize">
                                                                                    <span
                                                                                        :class="getStatusColor(data.status)">
                                                                                        {{ data.status }}
                                                                                    </span>
                                                                                </p>
                                                                            </h4>
                                                                            <p
                                                                                class="ml-4 text-sm font-medium text-gray-900">
                                                                                {{ data.total_units_completed }}/{{
                                                                                    data.total_units }} Units
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div class="grid">
                                                                        <div class="w-full mx-auto ">
                                                                            <div class="flex justify-between pt-5">
                    
                                                                                <p 
                                                                                    class="text-xs text-left">
                                                                                    Assignor: {{ data.assigned_by }}
                                                                                    <br /> 
                                                                                    <span v-if="data.assigned_at"> 
                                                                                        Assigned Date:  {{ data.assigned_at }}
                                                                                    </span> 
                                                                                    <br />
                                                                                    <span v-if="data.due_date"> 
                                                                                        Due Date:  {{ data.due_date }}
                                                                                    </span>
                                                                                     </p>
                                                                                     
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <p class="text-xs text-center pt-2 text-untitled-gray-500 font-bold">
                                                                        <v-button
                                                                            @click="gotoUnit(data.first_unit_id)"
                                                                            type="button"
                                                                            class="font-medium text-xs text-center">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none" viewBox="0 0 24 24"
                                                                                stroke-width="1.5"
                                                                                stroke="currentColor"
                                                                                class="w-3 h-3 mr-1">
                                                                                <path stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                                            </svg>
                                                                            Open Module
                                                                        </v-button>
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <p v-else class="text-center text-untitled-gray-500">No Modules
                                                            Found
                                                        </p>
                                                    </div>
                                                </div>
                                                <div :class="{ hidden: openTab !== 3, block: openTab === 3 }">
                                                    <LoadingState v-if="loadingCourses" class="mx-auto text-center" />
                                                    <div v-else>
                                                        <ul v-if="filteredCourseList.length > 0" role="list"
                                                            class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto"
                                                            :style="filteredCourseList.length < 3 ? 'height:auto' : 'height: 600px;'">
                                                            <li class="grid py-6 px-4  shadow-md m-2"
                                                                v-for="assigned_course in filteredCourseList"
                                                                :key="assigned_course.course_id">
                                                                <div class="flex-shrink-0 aspect-auto">
                                                                    <div>
                                                                        <img v-if="assigned_course.thumbnail"
                                                                            :src="assigned_course.thumbnail"
                                                                            class="w-full h-40" />
                                                                        <img v-else
                                                                            src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                                                                            class="w-full h-40" />
                                                                    </div>
                                                                </div>

                                                                <div class="mt-2 flex flex-1 flex-col ">
                                                                    <div>
                                                                        <div class="flex justify-between">
                                                                            <h4 class="text-sm">
                                                                                <a href="#"
                                                                                    class="font-medium text-gray-700 hover:text-gray-800">
                                                                                    {{ assigned_course.name }}</a>
                                                                                    <p class="capitalize">
                                                                                    <span
                                                                                        :class="getStatusColor(assigned_course.status)">
                                                                                        {{ assigned_course.status }}
                                                                                    </span>
                                                                                </p>
                                                                            </h4>
                                                                            <p
                                                                                class="ml-4 text-sm font-medium text-gray-900">
                                                                                {{ assigned_course.total_units_completed
                                                                                }}/{{ assigned_course.total_units }} Units
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div class="grid">
                                                                        <div class="w-full mx-auto ">
                                                                            <div class="flex justify-between pt-5  text-left">
                                                                                <p class="text-xs text-left">
                                                                                    Assignor: {{ assigned_course.assigned_by }}
                                                                                    <br /> 
                                                                                    <span v-if="assigned_course.assigned_at"> 
                                                                                        Assigned Date:  {{ assigned_course.assigned_at }}
                                                                                    </span>
                                                                                    <br />
                                                                                    <span v-if="assigned_course.due_date"> 
                                                                                        Due Date:  {{ assigned_course.due_date }}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                            <p class="hidden text-xs text-untitled-gray-500 font-bold text-center pt-5">
                                                                                <v-button
                                                                                    @click="gotoUnit(assigned_course.first_unit_id)"
                                                                                    type="button"
                                                                                    class="font-medium text-xs">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        fill="none" viewBox="0 0 24 24"
                                                                                        stroke-width="1.5"
                                                                                        stroke="currentColor"
                                                                                        class="w-3 h-3 mr-1">
                                                                                        <path stroke-linecap="round"
                                                                                            stroke-linejoin="round"
                                                                                            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                                                    </svg>
                                                                                    Open Course
                                                                                </v-button>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <br />
                                                                    <p class="text-xs text-untitled-gray-500 font-bold text-center">
                                                                        <v-button
                                                                            @click="gotoUnit(assigned_course.first_unit_id)"
                                                                            type="button"
                                                                            class="font-medium text-xs">
                                                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none" viewBox="0 0 24 24"
                                                                                stroke-width="1.5"
                                                                                stroke="currentColor"
                                                                                class="w-3 h-3 mr-1">
                                                                                <path stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                                            </svg>
                                                                            Open Course
                                                                        </v-button>
                                                                    </p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div v-else class="text-center">
                                                            <p class="text-untitled-gray-500">No courses assigned to
                                                                you.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div :class="{ hidden: openTab !== 4, block: openTab === 4 }">
                                                    <LoadingState v-if="loadingPlaylist" class="mx-auto text-center" />
                                                    <div v-else>
                                                        <ul v-if="playlists.length > 0" role="list"
                                                            class="grid lg:grid-cols-2 md:grid-cols-1 grid-cols-1 overflow-auto"
                                                            :style="playlists.length < 3 ? 'height:auto' : 'height: 600px;'">
                                                            <li class="grid py-6 px-4  shadow-md m-2"
                                                                v-for="assigned_playlist in playlists"
                                                                :key="assigned_playlist.playlist_id">
                                                                <div class="flex-shrink-0 aspect-auto"
                                                                    v-if="assigned_playlist.thumbnail">
                                                                    <div>
                                                                        <img v-if="assigned_playlist?.thumbnail?.includes('google')"
                                                                            src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                                                                            class="w-full h-40" />
                                                                        <img v-else-if="assigned_playlist.thumbnail"
                                                                            :src="assigned_playlist.thumbnail"
                                                                            class="w-full h-40" />
                                                                        <img v-else
                                                                            src="https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg"
                                                                            class="w-full h-40" />
                                                                    </div>
                                                                </div>

                                                                <div class="mt-2 flex flex-1 flex-col ">
                                                                    <div>
                                                                        <div class="flex justify-between">
                                                                            <h4 class="text-sm">
                                                                                <a href="#"
                                                                                    class="font-medium text-gray-700 hover:text-gray-800">
                                                                                    {{ assigned_playlist.name }}
                                                                                </a>
                                                                                <p class="capitalize">
                                                                                    <span
                                                                                        :class="getStatusColor(assigned_playlist.status)">
                                                                                        {{ assigned_playlist.status }}
                                                                                    </span>
                                                                                </p>
                                                                            </h4>
                                                                            <p
                                                                                class="ml-4 text-sm font-medium text-gray-900">
                                                                                {{ assigned_playlist.total_units_completed
                                                                                }}/{{ assigned_playlist.total_units }} Units
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                         
                                                                    <div class="grid">
                                                                        <div class="w-full mx-auto ">
                                                                            <div class="flex justify-between pt-5">
                                                                                <p class="text-xs text-left">
                                                                                    Assignor: {{ assigned_playlist.assigned_by }}
                                                                                    <br /> 
                                                                                    <span v-if="assigned_playlist.assigned_at"> 
                                                                                        Assigned Date:  {{ assigned_playlist.assigned_at }}
                                                                                    </span>
                                                                                    <br />
                                                                                    <span v-if="assigned_playlist.due_date"> 
                                                                                        Due Date:  {{ assigned_playlist.due_date }}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <p class="text-xs text-untitled-gray-500 font-bold text-center">
                                                                    <v-button
                                                                        @click="gotoUnit(assigned_playlist.first_unit_id, assigned_playlist.playlist_id)"
                                                                        type="button"
                                                                        class="font-medium text-xs">
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none" viewBox="0 0 24 24"
                                                                            stroke-width="1.5"
                                                                            stroke="currentColor"
                                                                            class="w-3 h-3 mr-1">
                                                                            <path stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                                                        </svg>
                                                                        Open Playlist
                                                                    </v-button>
                                                                </p>
                                                            </li>
                                                        </ul>

                                                        <div v-else class="text-center">
                                                            <p class="text-untitled-gray-500">No playlist assigned to you.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
</TransitionRoot>
</template>

<script setup>
import { ref, computed, defineEmits, inject, onMounted, watch } from "vue";
import {
    Dialog,
    DialogTitle,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import LoadingState from '@/components/partials/LoadingState';
import { useRouter } from 'vue-router';
import Bugsnag from '@bugsnag/js';
import { VButton } from 'revdojo-vue-components'
import ECombobox from "@/components/element-components/ECombobox";

const open = ref(true);
const openTab = ref(1);
const emit = defineEmits(["close"]);
const router = useRouter();
const axios = inject('axios');
const units = ref([]);
const modules = ref([]);
const courses = ref([]);
const playlists = ref([]);
const loadingUnits = ref(false);
const loadingModules = ref(false);
const loadingCourses = ref(false);
const loadingPlaylist = ref(false);
const assigned = ref({})
const sortBy = ref(null);
const sorts = ref([
    {
        name: 'A-Z',
        value: 'a-z'
    },
    {
        name: 'Z-A',
        value: 'z-a'
    },
    {
    name: 'Newest to Oldest',
    value: 'newest'
    },
    {
        name: 'Oldest to Newest',
        value: 'oldest'
    }
]);


function changeSort(sortBy) {
    switch (openTab.value) {
        case 1:
            sorting(filteredUnitsList.value, sortBy);
            break;
        case 2:
            sorting(filteredModuleList.value, sortBy);
            break;
        case 3:
            sorting(filteredCourseList.value, sortBy);
            break;
        case 4:
            sorting(playlists.value, sortBy);
    }
}


const sorting = (contents, sortBy) => {
    const sortData = ref(contents);
    sortData.value.sort((a, b) => {
        switch (sortBy) {
            case 'a-z':
                if (openTab.value == 1) {
                    return a.unit?.name.localeCompare(b.unit?.name);
                }
                return a.name.localeCompare(b.name);
            case 'z-a':
                if (openTab.value == 1) {
                    return b.unit?.name.localeCompare(a.unit?.name);
                }
                return b.name.localeCompare(a.name);
            case 'newest':
                return new Date(b.assigned_at) - new Date(a.assigned_at);
            case 'oldest':
                return new Date(a.assigned_at) - new Date(b.assigned_at);
        }
    });

    switch (openTab.value) {
        case 1:
            units.value = sortData.value;
            break;
        case 2:
            modules.value = sortData.value;
            break;
        case 3:
            courses.value = sortData.value;
            break;
        case 4:
            playlists.value = sortData.value;
    }
}


watch(sortBy, (val) => {
    changeSort(val);
});

const filteredCourseList = computed(() => {
    return courses.value ?? [];
});

const filteredModuleList = computed(() => {
    return modules.value ?? [];
});

const filteredUnitsList = computed(() => {
    return units.value ?? [];
});

const toggles = computed(() => {
    return [
        { id: 1, name: "Units", count: filteredUnitsList.value.length },
        { id: 2, name: "Modules", count: filteredModuleList.value.length },
        { id: 3, name: "Courses", count: filteredCourseList.value.length },
        { id: 4, name: "Playlist", count: playlists.value.length },
    ];
});

function toggleTabs(tab) {
    openTab.value = tab;
}

watch(openTab, () => {
    changeSort(sortBy.value);
});

function gotoUnit(unitId, playlistId = null) {
    if (playlistId) {
        router.push(`/units/${unitId}/${playlistId}`);
        return;
    }

    router.push(`/units/${unitId}`);
}
const close = () => {
    emit("close", open.value);
};

function getStatusColor(status) {
    if (status == 'completed' || status == 'Completed') {
        return 'text-green-500'
    }
    else if (status == 'overdue' || status == 'Overdue') {
        return 'text-red-500'
    }
    else if (status == 'incomplete' || status == 'In Progress') {
        return 'text-yellow-500'
    }
    else {
        return 'text-gray-500'
    }
}

const fetchAssignedContents = async () => {
    loadingUnits.value = true;
    await axios.get('/api/v3/user/my-progress/assigned-contents').then((response) => {
        assigned.value = response.data;
        units.value = response.data.units;
        courses.value = response.data.courses;
        modules.value = response.data.modules;
        playlists.value = response.data.playlists;
        loadingUnits.value = false;
    }).catch((error) => {
        Bugsnag.notify(error);
    }).finally(() => {
        loadingUnits.value = false;
    });
}

onMounted(async () => {
    await fetchAssignedContents();
    changeSort('newest');
});

</script>
