<template>
  <div
    class="border rounded-md p-5 shadow dark:bg-gray-800 dark:border-gray-700"
  >
    <div
      class="flex flex-col sm:flex-row justify-between gap-3 py-2"
      :class="`${isOpen ? 'border-b mb-5' : ''}`"
    >
      <div class="flex flex-col gap-2 xl:w-[84%] md:w-[73%]">
        <div class="flex gap-1 items-center">
          <div
            class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
          >
            <div
              class="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600"
            >
              <span class="font-medium text-gray-600 dark:text-gray-300">{{
                initials(note?.author?.name)
              }}</span>
            </div>
          </div>
          <small class="text-untitled-gray-800 font-semibold">{{
            note?.author?.name
          }}</small>
        </div>
        <h3 class="font-bold">{{ note?.unit?.name }}</h3>
        <p
          v-html="note?.value"
          class="text-xs text-untitled-gray-400 break-all"
        ></p>
      </div>
      <div class="flex flex-col justify-evenly gap-2 items-end">
        <small class="text-untitled-gray-400 text-xs"
          >Date Created:
          {{ dayjs(note?.created_at)?.format("MM/DD/YYYY") }}</small
        >
        <v-button
          @click="gotoUnit(note?.unit?.id)"
          class="w-[130px] flex justify-center items-center bg-[#E9ECF0] text-[#475466] text-xs"
          >View Unit</v-button
        >
        <v-button
          v-if="note?.note_replies?.length > 0"
          @click="isOpen = !isOpen"
          class="w-[130px] flex justify-center items-center bg-[#E9ECF0] text-[#475466] text-xs"
          >{{ isOpen ? 'Hide' : 'Open' }} Replies ({{ note?.note_replies?.length }})</v-button
        >
        <template v-if="note?.author?.id === user?.id">
          <v-button
            class="w-[130px] flex justify-center items-center bg-[#E9ECF0] text-[#475466] text-xs"
            @click="deleteNote(note)"
            >Delete Note</v-button
            
          >
          <v-button
            class="w-[130px] flex justify-center items-center bg-[#E9ECF0] text-[#475466] text-xs"
            @click="editNote(note)"
            >Edit Note</v-button
          >
        </template>
      </div>
    </div>

    <div class="flex flex-col gap-3" v-if="isOpen">
      <div
        v-for="reply in note?.note_replies"
        :key="reply?.id"
        class="flex justify-between gap-3"
      >
        <div class="flex flex-col gap-2 w-[82%] px-5">
          <div class="flex gap-1 items-center">
            <div
              class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
            >
              <div
                class="inline-flex overflow-hidden relative justify-center items-center w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-600"
              >
                <span class="font-medium text-gray-600 dark:text-gray-300">{{
                  initials(reply?.author?.name)
                }}</span>
              </div>
            </div>
            <small class="text-untitled-gray-800 font-semibold">{{
              reply?.author?.name
            }}</small>
          </div>
          <h3 class="font-bold">{{ reply?.unit?.name }}</h3>
          <p
            v-html="reply?.value"
            class="text-xs text-untitled-gray-400 break-all"
          ></p>
        </div>
        <div>
          <small class="text-untitled-gray-400 text-xs"
            >Date Created:
            {{ dayjs(reply?.created_at)?.format("MM/DD/YYYY") }}</small
          >
        </div>
      </div>
    </div>
  </div>

  <el-dialog v-model="editNoteDialog" width="30%" :show-close="false" >
    <template #header>
      <h4 class="font-bold">Edit Note</h4>
    </template>
    <div class="lg:h-auto md:h-auto">
      <div class="flex flex-col gap-3 px-6 py-6">
        <v-textarea v-model="currentNote.value" placeholder="Note" maxlength="2000"></v-textarea>
        <div class="flex justify-end items-center gap-2">
          <v-button :disabled="!currentNote?.value.length" @click="saveNoteAPI" :loading="loading">Save</v-button>
          <v-button @click="editNoteDialog = false" :disabled="loading">Cancel</v-button>
        </div>
      </div>
    </div>
  </el-dialog>

  <ConfirmDialog
    :open="confirmDialog"
    :is-custom="true"
    id="MyNote-ConfirmDialog-Dialogbox"
  >
    <template  #custom_html>
      <div class="text-sm text-gray-500">
        <p class="">This note will be deleted. Are you sure you want to delete?</p>
      </div>
    </template>
    <template #action_button>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button type="button" class="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 sm:ml-3 sm:w-auto" @click="onClickConfirmOk">OK</button>
          <button type="button" class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500 sm:mt-0 sm:w-auto" @click="confirmDialog = false" ref="cancelButtonRef">Cancel</button>
        </div>
    </template>
  </ConfirmDialog>
</template>

<script setup>
import { ref, defineProps,inject,computed } from "vue";
import { VButton,VTextarea } from "revdojo-vue-components";
import ConfirmDialog from '@/components/dialogs/ConfirmDialog';
import dayjs from "dayjs";
import { useRouter } from "vue-router";
import Bugsnag from "@bugsnag/js";
import eventBus from "@/plugins/eventBus";
import { useAuthStore } from "@/stores";
import { ElDialog } from "element-plus";
defineProps({
  note: {
    default: null,
    type: Object,
  },
});

const axios = inject('axios')
const router = useRouter();
const authUser = useAuthStore()
const isOpen = ref(false);
const editNoteDialog = ref(false);
const currentNote = ref(null);
const confirmDialog = ref(false);
const loading = ref(false);

const gotoUnit = (id) => {
  router.push(`/units/${id}`);
};


const deleteNoteAPI = (id) => {
  axios.delete(`/api/v3/notes/${id}`).then(() => {
    eventBus.$emit('DELETE_NOTE',id)
    
    eventBus.$emit("NOTIFICATION_DIALOG", {
      show: true,
      message: "Succesfully deleted note.",
    });
  })
  .catch(error => {
    Bugsnag.notify(error)
  })
}

const saveNoteAPI = () => {
  loading.value = true;

  axios.put(`/api/v3/notes`,{
    id: currentNote.value?.id,
    value: currentNote.value?.value
  }).then(({data}) => {
    loading.value = false;
    eventBus.$emit('UPDATE_NOTE',data)
    
    eventBus.$emit("NOTIFICATION_DIALOG", {
      show: true,
      message: "Succesfully update note.",
    });

    editNoteDialog.value = false
  })
  .catch(error => {
    Bugsnag.notify(error)
    loading.value = false;
  })
}

const editNote = (note) => {
  currentNote.value = {...note}
  editNoteDialog.value = true
}



const deleteNote = (note) => {
  currentNote.value = {...note}
  confirmDialog.value = true
}


const initials = (name) => {
  let initials = name.split(" ");

  if (initials.length > 1) {
    initials = initials.shift().charAt(0) + initials.pop().charAt(0);
  } else {
    initials = name.substring(0, 2);
  }

  return initials.toUpperCase();
};


const onClickConfirmOk = () => {
  deleteNoteAPI(currentNote?.value.id)
}


const user = computed(() => {
  return authUser.$state.user
})
</script>
