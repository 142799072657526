<template>
  <div class="flex flex-col items-end">
    <el-select-v2
      class="w-full"
      v-model="value"
      :options="customOptions"
      :placeholder="placeholder"
      :clearable="clearable"
      :filterable="filterable"
      :multiple="multiple"
      collapse-tags
      :disabled="disabled"
      :loading="loading"
      v-bind="attrs"
      :name="name"
      :allow-create="allowCreate"
      ref="selectInput"
      :reserve-keyword="reserveKeyword"
      placement="bottom"
      @clear="clear"
      @remove-tag="handleRemoveValue"
      :id="id"
      @change="handleValue"
    >
    </el-select-v2>
    <template v-if="multiple">
      <button
        v-if="
          props?.modelValue?.length < customOptions?.length &&
          customOptions?.length && allowSelectAll
        "
        :disabled="disabled"
        @click="selectAll"
        class="e-combobox-button"
      >
        <small>Select All</small>
      </button>
      <button
        v-if="
          props?.modelValue?.length >= customOptions?.length &&
          customOptions?.length
        "
        :disabled="disabled"
        @click="deselectAll"
        class="e-combobox-button hidden"
      >
        <small>Deselect</small>
      </button>
    </template>
  </div>

  <span class="text-red-500 text-xs italic mt-1">{{ errorMessage }}</span>
</template>

<script setup>
import { defineProps, defineEmits, computed, useAttrs, watch } from "vue";
import { ElSelectV2 } from "element-plus";
import { useField } from "vee-validate";
import {isEqual as _isEqual,debounce as _debounce} from 'lodash-es'

const props = defineProps({
  modelValue: {
    default: null,
  },
  options: {
    type: Object,
  },
  placeholder: {
    default: "Placeholder",
    type: String,
  },
  valueKey: {
    type: String,
    default: null,
    required: true,
  },
  optionName: {
    type: String,
    default: null,
    required: true,
  },
  disabled: {
    default: false,
    type: Boolean,
  },
  name: {
    default: "",
    type: String,
  },
  rules: {
    default: [],
  },
  multiple: {
    default: false,
    type: Boolean,
  },
  filterable: {
    default: false,
    type: Boolean,
  },
  clearable: {
    default: false,
    type: Boolean,
  },
  loading: {
    default: false,
    type: Boolean,
  },
  allowCreate: {
    type: Boolean,
    default: false,
  },
  reserveKeyword: {
    type: Boolean,
    default: false,
  },
  id:{
    type: String
  },
  allowSelectAll: {
    type: Boolean,
    default: true,
  },
  handleChange: {
    type: Function,
    default: () => {},
  },
});

const emit = defineEmits(["update:modelValue", "clear", "removeTag"]);

const attrs = useAttrs();

const clear = () => {
  emit("clear");
};

const selectAll = () => {
  const items = customOptions?.value?.map((item) => item.value);

  emit("update:modelValue", [...items]);
};

const deselectAll = () => {
  emit("update:modelValue", []);
};

const validateField = (value) => {
  let error = true;

  for (let i = 0; i < props.rules?.length; i++) {
    const rule = props.rules[i];

    error = rule(value);

    if (error != true) {
      break;
    }
  }

  emit("update:modelValue", value);

  return error;
};

const customOptions = computed(() => {
  if (!props?.options?.length) {
    return [];
  }

  if (typeof props?.options[0] !== "object") {
    return props?.options?.map((option) => {
      return {
        label: option,
        value: option,
      };
    });
  }

  return props?.options?.map((option) => {
    return {
      label: option[props?.optionName],
      value: option[props?.valueKey],
    };
  });
});


const debounceRefresh = _debounce(() => {
  const selectedItems = customOptions.value
      .filter((item) => {
        return props.modelValue.some((a) => a === item.value);
      })
      .map((item) => item.value);

      emit("update:modelValue", [...selectedItems]);
},1000)

watch(
  () => props.options,
  (current,prev) => {
    if(!_isEqual(current,prev)){
      debounceRefresh()
    }
  }
);

const { errorMessage, value } = useField(props.name, validateField, {
  validateOnValueUpdate: true,
  initialValue: props?.modelValue,
});



const handleRemoveValue = (removableValue) => {
  if (props.multiple) {
    emit("removeTag", removableValue);
  }
};

const handleValue = (value) => {
  emit("changedValue", value);
}
</script>

<style scoped>
.e-combobox-button {
  position: absolute;
  transform: translate(-36px, 7px);
  color: #9c9fa4;
  z-index: 10;
}
</style>
